var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-ask-password",
      attrs: {
        id: "askPassword",
        "header-class": "header-class-modal-doc-package",
        title: _vm.rendTitle,
        "ok-title": _vm.FormMSG(230, "Ok"),
        "cancel-title": _vm.FormMSG(285, "Cancel"),
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        "ok-variant": "primary",
        "ok-disabled":
          _vm.loading || _vm.passwordInput === "" || !_vm.useDigElecSign,
        "cancel-disabled": _vm.loading,
        "cancel-variant": "light",
        "button-size": "sm",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        show: _vm.resetModal,
        hidden: _vm.hiddenModal,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.loading, opacity: "0.85" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("loading", {
                        attrs: {
                          active: _vm.loading,
                          loader: "bars",
                          color: "#00A09C",
                          width: 64,
                        },
                        on: {
                          "update:active": function ($event) {
                            _vm.loading = $event
                          },
                        },
                      }),
                      _c(
                        "p",
                        {
                          staticClass: "loading-text",
                          attrs: { id: "cancel-label" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(297, "Please wait...")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "container-modal px-2" }, [
            _c(
              "form",
              {
                ref: "form",
                style: `margin-top: ${
                  _vm.$screen.width < 355 ? "25px" : "5px"
                }`,
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleSubmit.apply(null, arguments)
                  },
                },
              },
              [
                !_vm.justVerifyPassword
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          class: _vm.downloadESA,
                          staticStyle: { "font-size": "1rem" },
                          attrs: { href: "#" },
                          on: { click: _vm.downloadESA },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  295,
                                  "Click here to read Electronic Signature Agreement"
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { id: "agreeES" },
                              model: {
                                value: _vm.useDigElecSign,
                                callback: function ($$v) {
                                  _vm.useDigElecSign = $$v
                                },
                                expression: "useDigElecSign",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      106,
                                      "I agree to use electronic records and signatures"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "b-form-group",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      "invalid-feedback": `${
                        _vm.passwordInput === "" || !_vm.useDigElecSign
                          ? _vm.FormMSG(
                              296,
                              "You should enter your password and accept the terms."
                            )
                          : ""
                      }`,
                      state: _vm.passwordState,
                      label: _vm.FormMSG(101, "Password:"),
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "oldPassword",
                            type: _vm.showPassword ? "text" : "password",
                            state: _vm.passwordState,
                            required: "",
                            placeholder: _vm.FormMSG(
                              102,
                              "Enter your password."
                            ),
                          },
                          model: {
                            value: _vm.passwordInput,
                            callback: function ($$v) {
                              _vm.passwordInput = $$v
                            },
                            expression: "passwordInput",
                          },
                        }),
                        _c("b-input-group-append", [
                          _c(
                            "button",
                            {
                              staticClass: "btn-show-password",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.showPassword = !_vm.showPassword
                                },
                              },
                            },
                            [
                              !_vm.showPassword
                                ? _c("Eye", {
                                    attrs: { color: "#9c9d9e", size: 16 },
                                  })
                                : _vm._e(),
                              _vm.showPassword
                                ? _c("EyeOff", {
                                    attrs: { color: "#9c9d9e", size: 16 },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }